import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

function NewBookAnnouncementComponent() {
  return (
    <div>
      <StaticImage
        src="../../assets/images/splendeur-book/la-splendeur-au-lieu-des-cendres-announcement.webp"
        alt="Conférence la Splendeur au lieu des cendres"
        placeholder="blurred"
      />
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="btn center-btn"
        style={{ marginBottom: " 3rem", textTransform: "uppercase" }}
        href="https://amzn.eu/d/h9fhZhz"
      >
        Acheter
      </a>
    </div>
  );
}

export default NewBookAnnouncementComponent;
