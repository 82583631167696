import React from "react";
import { FaInstagram, FaYoutube } from "react-icons/fa";

const data = [
  {
    icon: <FaYoutube className="social-icon" />,
    url: "https://www.youtube.com/channel/UCrgmkseP89EXqSFOJLvYzMA",
  },
  {
    icon: <FaInstagram className="social-icon" />,
    url: "https://www.instagram.com/veillesurtoncoeur",
  },
];
const Links = data.map((link, i) => {
  return (
    <li key={i}>
      <a
        href={link.url}
        className="folowUp-link"
        target="_blank"
        rel="noreferrer"
      >
        {link.icon}
      </a>
    </li>
  );
});

const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{Links}</ul>
  );
};

export default SocialLinks;
