import React from "react";
import { FaAlignRight } from "react-icons/fa";

import Logo from "../assets/images/logo.png";
import NavLinks from "../constants/nav-links";
import NewBookAnnouncementHeaderComponent from "./new-book-annoncement/new-book-announcement-header";

const Navbar = ({ toggleSidebar }) => {
  return (
    <>
      <div className="fixed-top">
        <NewBookAnnouncementHeaderComponent />
        <nav className="navbar" style={{ position: "relative" }}>
          <div className="nav-center">
            <div className="nav-header">
              <div>
                <a href={"/"}>
                  <img src={Logo} alt="logo" />
                </a>
              </div>

              <button type="button" className="toggle-btn" onClick={toggleSidebar}>
                <FaAlignRight></FaAlignRight>
              </button>
            </div>
            <NavLinks styleClass="nav-links" gta={false} />
            <NavLinks styleClass="gta" gta={true} />
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
