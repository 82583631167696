import * as React from "react";
import { FaTimes } from "react-icons/fa";
import ReactModal from "react-modal";
import styled from "styled-components";
import modalBackground from "../../assets/images/splendeur-book/la-splendeur-au-lieu-des-cendres-announcement-bg.webp";

ReactModal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
    zIndex: 99999
  },
  content: {
    // marginTop: "5rem",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "840px",
    width: "90%",
    height: "fit-content",
    maxHeight: "max-content",
    padding: 0,
    border: 0,
    backgroundImage: `url(${modalBackground})`,
    backgroundPosition: "right"
  }
};

const ModalContainer = styled.div``;

const Modal = ({ modalOpen, toggleModal, children }) => {
  return (
    <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} contentLabel="Modal" style={modalStyles}>
      <ModalContainer>
        <button className="close-btn" onClick={toggleModal} style={{ zIndex: "1030" }}>
          <FaTimes />
        </button>
        {children}
      </ModalContainer>
    </ReactModal>
  );
};

export default Modal;
