import { Link } from "gatsby";
import React from "react";
import SocialLinks from "../constants/social-links";

import styled from "styled-components";

const FooterLink = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  a {
    color: white;
    padding: 0 1rem;

    :hover {
      color: var(--clr-primary-5);
    }
  }
`;

const Footer = () => {
  return (
    <footer className="footer">
      <FooterContainer>
        <FooterLink>
          <Link to="/qui-sommes-nous/">Qui sommes-nous</Link>
          <Link to="/contact/">Contactez-nous</Link>
          <Link to="/faire-un-don/">Faire un don</Link>
        </FooterLink>
        <SocialLinks styleClass="footer-links" />
        <FooterLink>
          <Link to="/politique-de-confidentialite/">Politique de confidentialité</Link>
          <Link to="/termes-et-conditions/">Termes et conditions</Link>
        </FooterLink>
        <h4>
          &copy; <span>Veille sur ton coeur</span> {new Date().getFullYear()}
        </h4>
      </FooterContainer>
    </footer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  @media screen and (max-width: 425px) {
    > div {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }
`;
