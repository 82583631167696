import { Link } from "gatsby";
import React from "react";

// [
//   {
//     text: "Évènements",
//     url: "/evenements/",
//     gta: false
//   },
//   {
//     text: "Inviter Sammantha Thom",
//     url: "/inviter/",
//     gta: false
//   },
//   {
//     text: "Culture",
//     url: "/culture/",
//     gta: false
//   }
// ];

const data = [
  {
    text: "Qui sommes-nous",
    url: "/qui-sommes-nous/",
    gta: false
  },
  {
    text: "Blogue",
    url: "/blog/",
    gta: false
  },
  {
    text: "Vidéos",
    url: "/ressources/",
    gta: false
  },
  {
    text: "Accompagnement",
    url: "/accompagnement/",
    gta: false
  },
  {
    text: "Livres",
    url: "/livres/",
    gta: false
  },
  {
    text: "Faire un don",
    url: "/faire-un-don/",
    gta: true
  }
];

const NavLinks = ({ styleClass, gta }) => {
  const menu = data.filter((item) => {
    if (gta === true) {
      if (item.gta) return item;
      return null;
    } else if (gta === false) {
      if (!item.gta) return item;
      return null;
    } else {
      return item;
    }
  });
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {menu.map((link, i) => {
        return (
          <li key={i}>
            <Link
              to={link.url}
              activeStyle={{
                color: `${link.gta ? "" : "var(--clr-primary-5)"}`
              }}
            >
              {link.text}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default NavLinks;
