import React from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "../assets/styles/theme";
import Footer from "./footer";
import Navbar from "./navbar";
import NewBookAnnouncementComponent from "./new-book-annoncement/new-book-announcement";
import Sidebar from "./sidebar";
import Modal from "./ui/modal";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${(props) => props.overflow && "hidden"};
  }
`;

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const windowGlobal = typeof window !== "undefined" && window;
  // windowGlobal.localStorage.removeItem("veilleSurTonCoeurCampaign");
  // windowGlobal.localStorage.removeItem("vstcConferenceCampaign");

  if (windowGlobal?.localStorage) {
    if (windowGlobal.localStorage.getItem("vstcNewBookAnnouncement") == null) {
      setTimeout(() => toggleModal(), 3000);
    }
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleModal = () => {
    if (!modalOpen) {
      if (windowGlobal?.localStorage) {
        windowGlobal.localStorage?.setItem("vstcNewBookAnnouncement", true);
      }
    }
    setModalOpen(!modalOpen);
  };
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle overflow={modalOpen} />
        <Navbar toggleSidebar={toggleSidebar} />
        <Sidebar toggleSidebar={toggleSidebar} isOpen={isOpen} />
        <Modal modalOpen={modalOpen} toggleModal={toggleModal}>
          {/* <Subscribe
            title={`Reçois gratuitement le ebook \n
          Les critères d’un bon mari de Sammantha Thom
          et notre Guide d'étude biblique`}
            dontShow
            showImage
            toggleModal={toggleModal}
          /> */}
          <NewBookAnnouncementComponent />
        </Modal>
        <ContainerMain>{children}</ContainerMain>
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Layout;

const ContainerMain = styled.main`
  padding-top: 4rem;

  @media screen and (max-width: 515px) {
    padding-top: 9rem;
  }
`;
